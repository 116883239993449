<template>
  <div>
    <v-text-field
      v-model="description2"
      label="Description"
      dense
      :rules="rules.default_max_500_character_and_no_empty_rule"
      readonly
      @click="description_dialogs()"
    ></v-text-field>

    <v-dialog v-model="BIRExpenseDialog" persistent max-width="80%">
      <b-i-r-expense v-on:data="on_close_description_dialog" :key="this.key"></b-i-r-expense>
      <v-btn color="error" @click="BIRExpenseDialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="RevolvingFund" persistent max-width="80%">
      <revolving-fund
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :title="this.title_revolving_fund"
        :particulars_id="this.particulars_id"
      ></revolving-fund>
      <v-btn color="error" @click="RevolvingFund = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="RevolvingFundHO" persistent max-width="80%">
      <revolving-fund-ho
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :particulars_id="this.particulars_id"
      ></revolving-fund-ho>
      <v-btn color="error" @click="RevolvingFundHO = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="RefundOthersPayment" persistent max-width="80%">
      <refund-others-payment v-on:data="on_close_description_dialog"
                             :key="this.key"></refund-others-payment>
      <v-btn color="error" @click="RefundOthersPayment = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="SalaryAndWages" persistent max-width="80%">
      <salary-and-wages
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :category_id="this.category_id"
        :branch_id="this.branch_id"
      ></salary-and-wages>
      <v-btn color="error" @click="SalaryAndWages = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="SalaryAndWagesIncentives" persistent max-width="80%">
      <salaries-and-wages-incentives
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :category_id="this.category_id"
        :branch_id="this.branch_id"
        :type_of_employee_items="this.type_of_incentives_items"
      ></salaries-and-wages-incentives>
      <v-btn color="error" @click="SalaryAndWagesIncentives = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BranchExpense" persistent max-width="80%">
      <branch-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></branch-expense>
      <v-btn color="error" @click="BranchExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Benefits" persistent max-width="80%">
      <benefits
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></benefits>
      <v-btn color="error" @click="Benefits = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="RegionalExpense" persistent max-width="80%">
      <regional-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></regional-expense>
      <v-btn color="error" @click="RegionalExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="TerritoryExpense" persistent max-width="80%">
      <territory-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></territory-expense>
      <v-btn color="error" @click="TerritoryExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Callers" persistent max-width="80%">
      <callers
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></callers>
      <v-btn color="error" @click="Callers = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PurchaseCasket" persistent max-width="80%">
      <purchase-casket
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></purchase-casket>
      <v-btn color="error" @click="PurchaseCasket = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="GLCares" persistent max-width="80%">
      <g-l-cares
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></g-l-cares>
      <v-btn color="error" @click="GLCares = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Claims" persistent max-width="80%">
      <claims
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></claims>
      <v-btn color="error" @click="Claims = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PaymentforTieUp" persistent max-width="80%">
      <paymentfor-tie-up
        v-on:maf_no="on_maf_no"
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></paymentfor-tie-up>
      <v-btn color="error" @click="PaymentforTieUp = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="CasketFactoryExpense" persistent max-width="80%">
      <casket-factory-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></casket-factory-expense>
      <v-btn color="error" @click="CasketFactoryExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="AnnualConExpense" persistent max-width="80%">
      <annual-con-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></annual-con-expense>
      <v-btn color="error" @click="AnnualConExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BODAllowance" persistent max-width="80%">
      <b-o-d-allowance
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :title="this.title_incentives"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></b-o-d-allowance>
      <v-btn color="error" @click="BODAllowance = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="EmployeeLoan" persistent max-width="80%">
      <employee-loan
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></employee-loan>
      <v-btn color="error" @click="EmployeeLoan = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="MAFJobOrder" persistent max-width="80%">
      <m-a-f-job-order
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></m-a-f-job-order>
      <v-btn color="error" @click="MAFJobOrder = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ORJobOrder" persistent max-width="80%">
      <o-r-job-order
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></o-r-job-order>
      <v-btn color="error" @click="ORJobOrder = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="CashAdvance" persistent max-width="80%">
      <cash-advance
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></cash-advance>
      <v-btn color="error" @click="CashAdvance = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Refund" persistent max-width="80%">
      <refund
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></refund>
      <v-btn color="error" @click="Refund = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="CheckBookProcess" persistent max-width="80%">
      <check-book-process
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></check-book-process>
      <v-btn color="error" @click="CheckBookProcess = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="GoodlifePropertyExpense" persistent max-width="80%">
      <goodlife-property-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></goodlife-property-expense>
      <v-btn color="error" @click="GoodlifePropertyExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BusinessPermit" persistent max-width="80%">
      <business-permit
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></business-permit>
      <v-btn color="error" @click="BusinessPermit = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="InternetExpense" persistent max-width="80%">
      <internet-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></internet-expense>
      <v-btn color="error" @click="InternetExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BuildingMaintainance" persistent max-width="80%">
      <building-maintainance
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></building-maintainance>
      <v-btn color="error" @click="BuildingMaintainance = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BuildingConstruction" persistent max-width="80%">
      <building-construction
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></building-construction>
      <v-btn color="error" @click="BuildingConstruction = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="OfficeRental" persistent max-width="80%">
      <office-rental
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></office-rental>
      <v-btn color="error" @click="OfficeRental = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ChapelBldgRental" persistent max-width="80%">
      <office-rental
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></office-rental>
      <v-btn color="error" @click="ChapelBldgRental = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="DepartmentSupply" persistent max-width="80%">
      <department-supply
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></department-supply>
      <v-btn color="error" @click="DepartmentSupply = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="StuffHouse" persistent max-width="80%">
      <stuff-house
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></stuff-house>
      <v-btn color="error" @click="StuffHouse = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="TravelExpense" persistent max-width="80%">
      <travel-expense
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></travel-expense>
      <v-btn color="error" @click="TravelExpense = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PurchaseITProductandAccessories" persistent max-width="80%">
      <purchase-i-t-productand-accessories
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></purchase-i-t-productand-accessories>
      <v-btn color="error" @click="PurchaseITProductandAccessories = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="BODAnnualShare" persistent max-width="80%">
      <b-o-d-annual-share
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></b-o-d-annual-share>
      <v-btn color="error" @click="BODAnnualShare = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ChapelEquipment" persistent max-width="80%">
      <chapel-equipment
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></chapel-equipment>
      <v-btn color="error" @click="ChapelEquipment = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ElectricBill" persistent max-width="80%">
      <electric-bill
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></electric-bill>
      <v-btn color="error" @click="ElectricBill = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="WaterBill" persistent max-width="80%">
      <water-bill
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></water-bill>
      <v-btn color="error" @click="WaterBill = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="LotRental" persistent max-width="80%">
      <lot-rental
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></lot-rental>
      <v-btn color="error" @click="LotRental = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="AutoLoan" persistent max-width="80%">
      <auto-loan
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></auto-loan>
      <v-btn color="error" @click="AutoLoan = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PurchaseMotor" persistent max-width="80%">
      <purchase-motor
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></purchase-motor>
      <v-btn color="error" @click="PurchaseMotor = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="OfficeEquipment" persistent max-width="80%">
      <office-equipment
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></office-equipment>
      <v-btn color="error" @click="OfficeEquipment = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="OutreachProgram" persistent max-width="80%">
      <outreach-program
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></outreach-program>
      <v-btn color="error" @click="OutreachProgram = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PaymentforInsurance" persistent max-width="80%">
      <paymentfor-insurance
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></paymentfor-insurance>
      <v-btn color="error" @click="PaymentforInsurance = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Incentives" persistent max-width="80%">
      <incentives
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :title="this.title_incentives"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :type_of_incentives_items="this.type_of_incentives_items"
        :particulars_description_items="this.particulars_description_items"
      ></incentives>
      <v-btn color="error" @click="Incentives = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PurchaseAuto" persistent max-width="80%">
      <purchase-auto
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></purchase-auto>
      <v-btn color="error" @click="PurchaseAuto = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="PurchaseLandProperty" persistent max-width="80%">
      <purchase-land-property
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></purchase-land-property>
      <v-btn color="error" @click="PurchaseLandProperty = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ThirtenMonthPay" persistent max-width="80%">
      <thirten-month-pay
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :category_id="this.category_id"
        :is_fourteen="this.is_fourteen"
      ></thirten-month-pay>
      <v-btn color="error" @click="ThirtenMonthPay = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="AutoMaintenance" persistent max-width="50%">
      <auto-maintenance
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :is_liquidation="this.is_liquidation"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></auto-maintenance>
      <v-btn color="error" @click="AutoMaintenance = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="CompanyIncentives" persistent max-width="50%">
      <company-incentives
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></company-incentives>
      <v-btn color="error" @click="CompanyIncentives = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="ProfessionalFee" persistent max-width="50%">
      <professional-fee
        v-on:data="on_close_description_dialog"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></professional-fee>
      <v-btn color="error" @click="ProfessionalFee = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="EmployeeIncentives" persistent max-width="50%">
      <employee-incentives
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
        :key="this.key"
        :branch_id="this.branch_id"
        :category_id="this.category_id"
        :particulars_id="this.particulars_id"
        :particulars_description_items="this.particulars_description_items"
      ></employee-incentives>
      <v-btn color="error" @click="EmployeeIncentives = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="GGCLoan" persistent max-width="50%">
      <g-g-c-loan
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
      ></g-g-c-loan>
      <v-btn color="error" @click="GGCLoan = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="CashBondReleased" persistent max-width="50%">
      <cash-bond-released
        :category_id="this.category_id"
        v-on:data="on_close_description_dialog"
        v-on:amount="on_close_description_dialog_have_amount"
      ></cash-bond-released>
      <v-btn color="error" @click="CashBondReleased = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
import {mdiAccountSearchOutline, mdiAlertOutline} from '@mdi/js'
import {mapActions, mapGetters} from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import BIRExpense from '@/views/file_interface/create/request/components/BIRExpense'
import RevolvingFund from '@/views/file_interface/create/request/components/RevolvingFund'
import RefundOthersPayment
  from '@/views/file_interface/create/request/components/RefundOthersPayment'
import SalaryAndWages from '@/views/file_interface/create/request/components/SalariesAndWages'
import BranchExpense from '@/views/file_interface/create/request/components/BranchExpense'
import Benefits from '@/views/file_interface/create/request/components/Benefits'
import RegionalExpense from '@/views/file_interface/create/request/components/RegionalExpense'
import TerritoryExpense from '@/views/file_interface/create/request/components/TerritoryExpense'
import Callers from '@/views/file_interface/create/request/components/Callers'
import PurchaseCasket from '@/views/file_interface/create/request/components/PurchaseCasket'
import GLCares from '@/views/file_interface/create/request/components/GLCares'
import Claims from '@/views/file_interface/create/request/components/Claims'
import PaymentforTieUp from '@/views/file_interface/create/request/components/PaymentforTieUp'
import CasketFactoryExpense
  from '@/views/file_interface/create/request/components/CasketFactoryExpense'
import AnnualConExpense from '@/views/file_interface/create/request/components/AnnualConExpense'
import BODAllowance from '@/views/file_interface/create/request/components/BODAllowance'
import EmployeeLoan from '@/views/file_interface/create/request/components/EmployeeLoan'
import MAFJobOrder from '@/views/file_interface/create/request/components/MAFJobOrder'
import ORJobOrder from '@/views/file_interface/create/request/components/ORJobOrder'
import CashAdvance from '@/views/file_interface/create/request/components/CashAdvance'
import Refund from '@/views/file_interface/create/request/components/Refund'
import CheckBookProcess from '@/views/file_interface/create/request/components/CheckBookProcess'
import GoodlifePropertyExpense
  from '@/views/file_interface/create/request/components/GoodlifePropertyExpense'
import BusinessPermit from '@/views/file_interface/create/request/components/BusinessPermit'
import InternetExpense from '@/views/file_interface/create/request/components/InternetExpense'
import BuildingMaintainance
  from '@/views/file_interface/create/request/components/BuildingMaintainance'
import BuildingConstruction
  from '@/views/file_interface/create/request/components/BuildingConstruction'
import OfficeRental from '@/views/file_interface/create/request/components/OfficeRental'
import ChapelBldgRental from '@/views/file_interface/create/request/components/ChapelBldgRental'
import DepartmentSupply from '@/views/file_interface/create/request/components/DepartmentSupply'
import StuffHouse from '@/views/file_interface/create/request/components/StuffHouse'
import TravelExpense from '@/views/file_interface/create/request/components/TravelExpense'
import PurchaseITProductandAccessories
  from '@/views/file_interface/create/request/components/PurchaseITProductandAccessories'
import BODAnnualShare from '@/views/file_interface/create/request/components/BODAnnualShare'
import ChapelEquipment from '@/views/file_interface/create/request/components/ChapelEquipment'
import ElectricBill from '@/views/file_interface/create/request/components/ElectricBill'
import WaterBill from '@/views/file_interface/create/request/components/WaterBill'
import LotRental from '@/views/file_interface/create/request/components/LotRental'
import AutoLoan from '@/views/file_interface/create/request/components/AutoLoan'
import PurchaseMotor from '@/views/file_interface/create/request/components/PurchaseMotor'
import OfficeEquipment from '@/views/file_interface/create/request/components/OfficeEquipment'
import OutreachProgram from '@/views/file_interface/create/request/components/OutreachProgram'
import PaymentforInsurance
  from '@/views/file_interface/create/request/components/PaymentforInsurance'
import Incentives from '@/views/file_interface/create/request/components/Incentives'
import PurchaseAuto from '@/views/file_interface/create/request/components/PurchaseAuto'
import PurchaseLandProperty
  from '@/views/file_interface/create/request/components/PurchaseLandProperty'
import ThirtenMonthPay from '@/views/file_interface/create/request/components/ThirtenMonthPay'
import AutoMaintenance from '@/views/file_interface/create/request/components/AutoMaintenance'
import RevolvingFundHo from '@/views/file_interface/create/request/components/RevolvingFundHO'
import CompanyIncentives from '@/views/file_interface/create/request/components/CompanyIncentives'
import ProfessionalFee from "@/views/file_interface/create/request/components/ProfessionalFee";
import GGCLoan from "@/views/file_interface/create/request/components/GGCLoan";
import EmployeeIncentives
  from "@/views/file_interface/create/request/components/EmployeeIncentives";
import SalariesAndWagesIncentives
  from "@/views/file_interface/create/request/components/SalariesAndWagesIncentives";
import CashBondReleased
  from "@/views/file_interface/create/request/components/CashBondReleased";

export default {
  props: {
    is_regular: Number,
    is_pdc: Number,
    is_ftv: Number,
    description: String,
    category_id: Number,
    particulars_id: Number,
    particulars_items: Array,
    branch_id: Number,
    particulars_description_items: Array,
    is_liquidation: Boolean,
  },
  components: {
    SalariesAndWagesIncentives,
    CashBondReleased,
    GGCLoan,
    EmployeeIncentives,
    ProfessionalFee,
    snackBarDialog,
    AutoMaintenance,
    BIRExpense,
    RevolvingFund,
    RefundOthersPayment,
    SalaryAndWages,
    BranchExpense,
    Benefits,
    RegionalExpense,
    TerritoryExpense,
    Callers,
    PurchaseCasket,
    GLCares,
    Claims,
    PaymentforTieUp,
    CasketFactoryExpense,
    AnnualConExpense,
    BODAllowance,
    EmployeeLoan,
    MAFJobOrder,
    ORJobOrder,
    CashAdvance,
    Refund,
    CheckBookProcess,
    GoodlifePropertyExpense,
    BusinessPermit,
    InternetExpense,
    BuildingMaintainance,
    BuildingConstruction,
    OfficeRental,
    ChapelBldgRental,
    DepartmentSupply,
    StuffHouse,
    TravelExpense,
    PurchaseITProductandAccessories,
    BODAnnualShare,
    ChapelEquipment,
    ElectricBill,
    WaterBill,
    LotRental,
    AutoLoan,
    PurchaseMotor,
    OfficeEquipment,
    OutreachProgram,
    PaymentforInsurance,
    Incentives,
    PurchaseAuto,
    PurchaseLandProperty,
    ThirtenMonthPay,
    RevolvingFundHo,
    CompanyIncentives,
  },
  mounted() {
    this.description2 = this.description
  },
  setup() {
    return {
      is_fourteen: false,
      disable_amount: false,
      description2: '',

      key: 0,
      amount: '',

      title_incentives: '',
      title_revolving_fund: '',
      type_of_incentives_items: [],
      maf_no: null,

      description_strict: false,

      CashBondReleased: false,
      EmployeeIncentives: false,
      CompanyIncentives: false,
      ProfessionalFee: false,
      BIRExpenseDialog: false,
      RevolvingFund: false,
      RevolvingFundHO: false,
      RefundOthersPayment: false,
      SalaryAndWages: false,
      SalaryAndWagesIncentives: false,
      BranchExpense: false,
      Benefits: false,
      RegionalExpense: false,
      TerritoryExpense: false,
      Callers: false,
      PurchaseCasket: false,
      GLCares: false,
      Claims: false,
      PaymentforTieUp: false,
      CasketFactoryExpense: false,
      AnnualConExpense: false,
      BODAllowance: false,
      GGCLoan: false,
      EmployeeLoan: false,
      MAFJobOrder: false,
      ORJobOrder: false,
      CashAdvance: false,
      Refund: false,
      CheckBookProcess: false,
      GoodlifePropertyExpense: false,
      BusinessPermit: false,
      InternetExpense: false,
      BuildingMaintainance: false,
      BuildingConstruction: false,
      OfficeRental: false,
      ChapelBldgRental: false,
      DepartmentSupply: false,
      StuffHouse: false,
      TravelExpense: false,
      PurchaseITProductandAccessories: false,
      BODAnnualShare: false,
      ChapelEquipment: false,
      ElectricBill: false,
      WaterBill: false,
      LotRental: false,
      AutoLoan: false,
      PurchaseMotor: false,
      OfficeEquipment: false,
      OutreachProgram: false,
      PaymentforInsurance: false,
      Incentives: false,
      PurchaseAuto: false,
      PurchaseLandProperty: false,
      ThirtenMonthPay: false,
      AutoMaintenance: false,
      ca_employee_id: 0,
      is_ca: false,
    }
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('employee', ['search_all_active_employee']),
    ...mapActions('request', ['done_request_form']),
    ...mapActions('affiliatee', ['search_all_active_affiliate']),
    ...mapActions('request_data', ['register_request_data']),
    description_dialogs() {
      this.key++
      if (this.branch_id === 0) {
        this.change_snackbar({
          show: true,
          color: 'error',
          text: 'PLEASE SELECT BRANCH/REGION/TERRITORY/DEPARTMENT FIRST!!!',
        })
      } else {
        if (this.particulars_items.length > 0) {
          var index = this.particulars_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.particulars_id)
          if (index != -1) {
            this.is_ca = false
            switch (this.particulars_items[index].particulars) {
              case 'BIR Expense': {
                this.BIRExpenseDialog = true
              }
                break
              case 'Revolving Fund': {
                if (this.category_id === 2) {
                  this.RevolvingFundHO = true
                } else {
                  this.title_revolving_fund = 'REVOLVING FUND'
                  this.RevolvingFund = true
                }
              }
              case 'Chapel Fund': {
                this.title_revolving_fund = 'CHAPEL FUND'
                this.RevolvingFund = true
              }
                break
              case 'Refund/Other Payment': {
                this.RefundOthersPayment = true
              }
              case 'Cash Bond Release': {
                this.CashBondReleased = true
              }
                break
              case 'Company Incentives': {
                this.CompanyIncentives = true
              }
                break
              case 'Employee Incentives': {
                this.EmployeeIncentives = true
              }
                break
              case 'Professional Fee/Retainers Fee': {
                this.ProfessionalFee = true
              }
                break
              case 'Salary & Wages':
              case 'HO - Salary & Wages': {
                this.description_strict = true
                this.SalaryAndWages = true
              }
                break
              case 'Branch Expense': {
                this.BranchExpense = true
              }
                break
              case 'Benefits': {
                this.Benefits = true
              }
                break
              case 'Auto Maintainance': {
                this.AutoMaintenance = true
              }
                break
              case 'Regional Expense': {
                this.RegionalExpense = true
              }
                break
              case 'Territory Expense': {
                this.TerritoryExpense = true
              }
                break
              case 'Callers': {
                this.Callers = true
              }
                break
              case 'Purchase Casket': {
                this.PurchaseCasket = true
              }
                break
              case 'GL Cares': {
                this.GLCares = true
              }
                break
              case 'Claims': {
                this.Claims = true
              }
                break
              case 'Payment for Tie Up': {
                this.PaymentforTieUp = true
              }
                break
              case 'Casket Factory Expense': {
                this.CasketFactoryExpense = true
              }
                break
              case 'Annual Con Expense': {
                this.AnnualConExpense = true
              }
                break
              case 'BOD - Allowance': {
                this.title_incentives = 'BOD ALLOWANCES'
                this.BODAllowance = true
              }
                break
              case 'Employee Allowance': {
                this.title_incentives = 'EMPLOYEE ALLOWANCES'
                this.BODAllowance = true
              }
                break
              case 'GGC Loan': {
                if (this.is_pdc) {
                  this.GGCLoan = true
                }
              }
                break
              case 'Employee Loan': {
                this.EmployeeLoan = true
              }
                break
              case 'Maf Job Order': {
                this.MAFJobOrder = true
              }
                break
              case 'OR Job Order': {
                this.ORJobOrder = true
              }
                break
              case 'Cash Advance': {
                this.CashAdvance = true
                this.is_ca = true
              }
                break
              case 'Refund': {
                this.Refund = true
              }
                break
              case 'Check Book Process': {
                this.CheckBookProcess = true
              }
                break
              case 'Goodlife Property Expense':
              case 'Property Plant & Equipment': {
                this.GoodlifePropertyExpense = true
              }
                break
              case 'Business Permit': {
                this.BusinessPermit = true
              }
                break
              case 'Internet/Load Expense':
              case 'Load Expense':
              case 'Internet Expense':
              case 'Internet': {
                this.InternetExpense = true
              }
                break
              case 'Building Maintainance': {
                this.BuildingMaintainance = true
              }
                break
              case 'Building Construction': {
                this.BuildingConstruction = true
              }
                break
              case 'Office Rental': {
                this.OfficeRental = true
              }
                break
              case 'Chapel Bldg Rental': {
                this.ChapelBldgRental = true
              }
                break
              case 'Department Supply': {
                this.DepartmentSupply = true
              }
                break
              case 'Stuff House': {
                this.StuffHouse = true
              }
                break
              case 'Travel Expense': {
                this.TravelExpense = true
              }
                break
              case 'Purchase IT Product and Accessories': {
                this.PurchaseITProductandAccessories = true
              }
                break
              case 'BOD - Annual Share': {
                this.BODAnnualShare = true
              }
                break
              case 'Chapel Equipment': {
                this.ChapelEquipment = true
              }
                break
              case 'Electric Bill': {
                this.ElectricBill = true
              }
                break
              case 'Water Bill': {
                this.WaterBill = true
              }
                break
              case 'Lot Rental': {
                this.LotRental = true
              }
                break
              case 'Auto Loan': {
                this.AutoLoan = true
              }
                break
              case 'Purchase Motor': {
                this.PurchaseMotor = true
              }
                break
              case 'Office Equipment': {
                this.OfficeEquipment = true
              }
                break
              case 'Outreach Program': {
                this.OutreachProgram = true
              }
                break
              case 'Payment for Insurance': {
                this.PaymentforInsurance = true
              }
                break
              case 'Incentives': {
                this.Incentives = true
                this.title_incentives = this.particulars_items[index].particulars
                if (this.category_id === 4) {
                  this.Incentives = false
                  this.SalaryAndWagesIncentives = true
                  this.type_of_incentives_items = [
                    'BM Incentives',
                    'Production Incentives',
                  ]
                } else if (this.category_id === 3) {
                  this.Incentives = false
                  this.SalaryAndWagesIncentives = true
                  this.type_of_incentives_items = ['CMI', 'RCMI', 'CDI']
                  // this.type_of_incentives_items = ['Callers Incentives', 'Chapel Manager Incentives', 'Regional Chapel Manager Incentives', 'Chapel Director Incentives']
                }
              }
                break
              case 'RM Incentives':
              case 'TH Incentives':
              case 'VP Incentives': {
                this.type_of_incentives_items = [this.particulars_items[index].particulars]
                this.Incentives = true
                this.title_incentives = this.particulars_items[index].particulars
              }
                break
              case 'Purchase Auto':
              case 'Purchase Auto/Funeral Car':
              case 'Purchase Funeral Car': {
                this.PurchaseAuto = true
              }
                break
              case 'Purchase Land Property': {
                this.PurchaseLandProperty = true
              }
                break
              case '13th Month Payroll': {
                this.is_fourteen = false
                this.ThirtenMonthPay = true
              }
                break
              case '14th Month Payroll': {
                this.is_fourteen = true
                this.ThirtenMonthPay = true
              }
                break
              default:
                break
            }
          }
        }
      }
    },
    on_close_description_dialog(value) {
      this.GGCLoan = false
      this.BIRExpenseDialog = false
      this.RevolvingFund = false
      this.RevolvingFundHO = false
      this.RefundOthersPayment = false
      this.SalaryAndWages = false
      this.SalaryAndWagesIncentives = false
      this.BranchExpense = false
      this.Benefits = false
      this.AutoMaintenance = false
      this.RegionalExpense = false
      this.TerritoryExpense = false
      this.Callers = false
      this.PurchaseCasket = false
      this.GLCares = false
      this.Claims = false
      this.PaymentforTieUp = false
      this.CasketFactoryExpense = false
      this.AnnualConExpense = false
      this.BODAllowance = false
      this.EmployeeLoan = false
      this.MAFJobOrder = false
      this.ORJobOrder = false
      this.CashAdvance = false
      this.Refund = false
      this.CheckBookProcess = false
      this.GoodlifePropertyExpense = false
      this.BusinessPermit = false
      this.InternetExpense = false
      this.BuildingMaintainance = false
      this.BuildingConstruction = false
      this.OfficeRental = false
      this.ChapelBldgRental = false
      this.DepartmentSupply = false
      this.TravelExpense = false
      this.StuffHouse = false
      this.PurchaseITProductandAccessories = false
      this.BODAnnualShare = false
      this.ChapelEquipment = false
      this.ElectricBill = false
      this.WaterBill = false
      this.LotRental = false
      this.AutoLoan = false
      this.PurchaseMotor = false
      this.OfficeEquipment = false
      this.OutreachProgram = false
      this.PaymentforInsurance = false
      this.Incentives = false
      this.PurchaseAuto = false
      this.PurchaseLandProperty = false
      this.ThirtenMonthPay = false
      this.CompanyIncentives = false
      this.ProfessionalFee = false
      this.EmployeeIncentives = false
      this.CashBondReleased = false
      this.$emit('on_close_description_dialog', value)
      this.$emit('on_is_ca', this.is_ca)
      this.description2 = value
    },
    on_maf_no(value) {
      this.maf_no = value
      this.$emit('on_maf_no', value)
    },
    on_close_description_dialog_have_amount(value) {
      this.$emit('on_close_description_dialog_have_amount', value)
      this.disable_amount = true
      this.amount = value
    },
  },
}
</script>

<style scoped>
</style>
